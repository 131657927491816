import { useParams } from 'react-router-dom';

import Auction from '../classes/Auction';
import AuctionList from '../classes/AuctionList';
import FullScreenGallery from '../components/FullScreenGallery/FullScreenGallery';
import { FullScreenGalleryImage } from '../components/FullScreenGallery/FullScreenGallery';

interface IProps {
	auctionList: AuctionList;
}

export default function Auction2Page(props: IProps) {
	const { nr } = useParams();

	const getImages = (): Array<FullScreenGalleryImage[]> => {
		const output: Array<FullScreenGalleryImage[]> = [];
		let auction: Auction | undefined = props.auctionList.list.find((e: Auction) => {
			return e.name === nr;
		});
		if (auction) {
			for (let i of auction.lotGroupList) {
				output.push(auction.mapToFullScreenGalleryItem(i));
			}
		}
		return output;
	};

	const getPdf = (): string => {
		let auction: Auction | undefined = props.auctionList.list.find((e: Auction) => {
			return e.name === nr;
		});
		if (auction) {
			return auction.pdfPath;
		}
		return '';
	};

	return (
		<>
			<div className="d-flex justify-content-between">
				<h1 style={{ marginBottom: '0px' }}>Auktion {nr}</h1>
				<a
					type="button"
					className="btn btn-primary"
					style={{ marginTop: '8px' }}
					href={getPdf()}
					download="Auktion"
					target="_blank"
					rel="noreferrer"
				>
					Download Katalog
				</a>
			</div>

			<hr />
			<p>
				<strong>Lose der Auktion {nr}</strong>
			</p>
			{getImages().map((e: FullScreenGalleryImage[], i: number) => (
				<div style={{ marginTop: '30px' }} key={i}>
					<div
						style={{
							backgroundColor: '#ddd',
							color: 'grey',
							fontWeight: '600',
							padding: '5px 10px 5px 10px',
							marginBottom: '10px',
						}}
					>
						{e[0].description}
					</div>
					<FullScreenGallery images={e} />
				</div>
			))}
			<br />
		</>
	);
}
