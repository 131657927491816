import * as React from 'react';
import { useState } from 'react';
import './FullScreenGallery.scss';

export type FullScreenGalleryImage = {
	original: string;
	alt: string;
	description?: string | undefined;
};

interface IProps {
	images: FullScreenGalleryImage[];
}

export const ImageGalleryContainer2: React.FC<IProps> = (props: IProps) => {
	const [show, setShow] = useState<boolean>(false);
	const [index, setIndex] = useState<number>(0);

	const next = (e: any): void => {
		e.stopPropagation();
		let newIndex = index + 1;
		if (newIndex >= props.images.length) newIndex = 0;
		setIndex(newIndex);
	};

	const previous = (e: any): void => {
		e.stopPropagation();
		let newIndex = index - 1;
		if (newIndex < 0) newIndex = props.images.length - 1;
		setIndex(newIndex);
	};
	const open = (input: number): void => {
		setIndex(input);
		setShow(true);
	};

	const exit = (): void => {
		setShow(false);
	};

	const renderFullscreen = (): JSX.Element => {
		if (show) {
			return (
				<>
					<div id="overlay-container" onClick={exit}>
						<div
							style={{
								position: 'absolute',
								top: '20px',
								right: '20px',
							}}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								width="50"
								height="50"
								fill="rgba(255,255,255,0.8)"
							>
								<path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
							</svg>
						</div>
						<div onClick={previous}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="100"
								height="100"
								fill="rgba(255,255,255,0.8)"
								className="bi bi-chevron-compact-left"
								viewBox="0 0 16 16"
							>
								<path
									fillRule="evenodd"
									d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
								/>
							</svg>
						</div>
						<div className="overlay-item-picture">
							<img
								alt={
									props.images[index]
										.description
								}
								src={props.images[index].original}
							/>
							{props.images[index].description && (
								<div
									style={{
										padding: '10px',
										color: 'white',
										backgroundColor:
											'rgba(0,0,0,0.7)',
									}}
								>
									{
										props.images[index]
											.description
									}
								</div>
							)}
						</div>
						<div onClick={next}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="100"
								height="100"
								fill="rgba(255,255,255,0.8)"
								className="bi bi-chevron-compact-right"
								viewBox="0 0 16 16"
							>
								<path
									fillRule="evenodd"
									d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
								/>
							</svg>
						</div>
					</div>
				</>
			);
		} else {
			return <></>;
		}
	};

	return (
		<>
			<div className="thumbnail-container">
				{props.images.map((e: any, i: number) => (
					<div key={i}>
						<div className="input-picture-upload">
							<img
								onClick={() => open(i)}
								src={e.original}
								alt={e.description}
								className="img-fluid"
								style={{
									height: '100%',
									width: '100%',
									objectFit: 'contain',
								}}
							/>
						</div>
					</div>
				))}
			</div>
			{renderFullscreen()}
		</>
	);
};

export default ImageGalleryContainer2;
