type IProps = {
	children: JSX.Element;
};

const ProtectedRoute = ({ children }: IProps) => {
	//const token: string | null = getTokenFromLocalStorage();
	//if (token) {
	return <>{children}</>;
	//} else {
	//return <Navigate to="/login" replace />;
	//}
};

export default ProtectedRoute;
