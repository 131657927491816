import axios from 'axios';

import Auction from '../classes/Auction';
import AuctionApi from '../interfaces/AuctionApi';
import { API_CURRENT } from '../constants/urls';

export default class AuctionList {
	list: Auction[];

	constructor() {
		this.list = [];
	}

	public async getFromApi(): Promise<void> {
		const url: string = `${API_CURRENT}/special/auction-folders`;
		const response = await axios.get(url);
		const data: AuctionApi[] = response.data;
		for (let i of data) {
			let auction: Auction = new Auction();
			auction.translateFromApi(i);
			this.list.push(auction);
		}
		this.list = this.list.reverse();
	}
}
