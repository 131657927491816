import ImageGallery from 'react-image-gallery';
import { images } from '../constants/images';

export default function Home() {
	return (
		<>
			<h1>Hier finden Sie Schätze für Ihre Sammlung</h1>
			<hr />
			<p>
				Unsere 3. Auktion findet am{' '}
				<b>Samstag 15. Juni 2024 um 14.00 Uhr</b> statt.
			</p>
			<p>
				Hier einige Highlights unserer aktuellen Auktion. Alle Lose und
				Beschriebe können Sie unter dem Menu «Auktionen» in Grossansicht
				anschauen. Auch unter{' '}
				<a
					href="https://www.philasearch.com/de/categories?set_anbieter=9514&set_auktionnr=10602"
					target="_blank"
					rel="noreferrer"
				>
					www.philasearch.com
				</a>{' '}
				können Sie die Lose abrufen und auch schon Gebote abgeben.
			</p>
			<br />
			<ImageGallery items={images} showPlayButton={false} />
			<br />
		</>
	);
}
