export default function Footer() {
	function scrollTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	return (
		<div id="footer">
			<div className="container">
				<div className="row">
					<div className="col-sm-12 col-lg-3 footer-left">
						<a href="/#/privacy" onClick={scrollTop}>
							Datenschutz
						</a>
						<br />
						<a href="/#/imprint" onClick={scrollTop}>
							Impressum
						</a>
						<br />
					</div>
					<div className="col-sm-12 col-lg-3 footer-left">
						{/*
						<a href="/#/conditions" onClick={scrollTop}>
							AGB
						</a>
						<br />
						*/}
						<a href="/#/auction-conditions" onClick={scrollTop}>
							Versteigerungsbedingungen
						</a>
						<br />
					</div>
					<div className="col-lg-3 offset-lg-3 d-sm-none d-lg-block footer-right">
						<div className="col-sm-12 col-lg-3"></div>
						<hr className="d-lg-none hrGold" />
						<span className="gold">
							© 2024 | R&J universal AG
						</span>
						<br />
					</div>
				</div>
			</div>
		</div>
	);
}
