import { NavLink } from 'react-router-dom';

import Auction from '../classes/Auction';

interface IProps {
	auctions: Auction[];
}

const Navigation = (props: IProps) => {
	return (
		<>
			<div className="headerbox">
				<div className="container">
					<div className="headerlogo">
						<img
							src="/img/Logo_RJ_gold.png"
							alt="Logo R&J Stamp"
							className="img-fluid"
						/>
					</div>
				</div>
			</div>
			<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
				<div className="container">
					<a
						className="navbar-brand d-block d-lg-none d-xl-none"
						href="/"
					>
						R&J Stamp
					</a>
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div
						className="collapse navbar-collapse"
						id="navbarSupportedContent"
					>
						<ul className="navbar-nav mr-auto">
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="home"
								>
									Home{' '}
									<span className="sr-only">
										(current)
									</span>
								</NavLink>
							</li>
							<li className="nav-item dropdown">
								<a
									className="nav-link dropdown-toggle"
									href="/"
									role="button"
									data-toggle="dropdown"
									aria-expanded="false"
								>
									Auktionen
								</a>
								<div className="dropdown-menu">
									{props.auctions.map(
										(
											auction: Auction,
											key: number
										) => (
											<NavLink
												key={
													key
												}
												className="dropdown-item"
												to={
													'auction/' +
													auction.name
												}
											>
												Auktion{' '}
												{
													auction.name
												}
											</NavLink>
										)
									)}
								</div>
							</li>
							<li className="nav-item">
								<NavLink
									className="nav-link"
									to="shop"
								>
									Shop
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Navigation;
