const Sell = () => {
	return (
		<>
			<h1>Verkaufen</h1>
			<hr />
			<p>
				Sie wollen Ihre Briefmarkensammlung auflösen und verkaufen? Oder Sie
				haben eine Sammlung geerbt und möchten diese nicht weiterführen?
				Dann kontaktieren Sie uns unverbindlich per E-Mail oder Telefon.
				Gerne stehen wir Ihnen beratend zur Verfügung und unterbreiten Ihnen
				ein faires Angebot.
				<br />
				<br />
				Telefon: +41 44 777 11 22
				<br />
				E-Mail: <a href="mailto:info@rj-stamp.ch">info@rj-stamp.ch</a>
			</p>
		</>
	);
};

export default Sell;
