import { ReactImageGalleryItem } from 'react-image-gallery';

import AuctionApi from '../interfaces/AuctionApi';
import { API_CURRENT } from '../constants/urls';
import { FullScreenGalleryImage } from '../components/FullScreenGallery/FullScreenGallery';

type Lot = {
	name: string;
	path: string;
};

export default class Auction {
	name: string;
	lotList: Lot[];
	lotGroupList: Array<Lot[]>;
	pdfPath: string;

	constructor() {
		this.name = '';
		this.lotList = [];
		this.lotGroupList = [];
		this.pdfPath = '';
	}

	translateFromApi(input: AuctionApi): void {
		this.name = input.name;
		for (let i of input.lotPathList) {
			let lot: Lot = {
				name: this.extractLotNameFromPath(i),
				path: this.cleanPath(i),
			};
			this.lotList.push(lot);
		}

		for (let i of this.lotList) {
			let group: Lot[] | undefined = this.lotGroupList.at(-1);
			if (group) {
				if (group[0].name === i.name) {
					group.push(i);
				} else {
					this.lotGroupList.push([i]);
				}
			} else {
				this.lotGroupList.push([i]);
			}
		}

		this.pdfPath = this.cleanPath(input.pdfPath);
	}

	extractLotNameFromPath(path: string): string {
		const temp: string[] = path.split('/');
		const output: string = temp.at(-2) ?? '';
		return output.replace(/.*_/, '');
	}

	mapToReactImageGalleryItem(): ReactImageGalleryItem[] {
		const output: ReactImageGalleryItem[] = [];
		for (const i of this.lotList) {
			const name: string = 'Los ' + i.name;
			output.push({
				original: i.path,
				thumbnail: i.path,
				originalAlt: name,
				thumbnailAlt: name,
				thumbnailLabel: name,
			});
		}
		return output;
	}

	mapToFullScreenGalleryItem(lotList?: Lot[]): FullScreenGalleryImage[] {
		if (lotList === undefined) {
			lotList = this.lotList;
		}
		const output: FullScreenGalleryImage[] = [];
		for (const i of lotList) {
			const name: string = i.name;
			output.push({
				original: i.path,
				alt: i.path,
				description: 'Los ' + Number(name),
			});
		}
		return output;
	}

	private cleanPath(input: string): string {
		return API_CURRENT + '/' + input.slice(7);
	}
}
