export const images = [
	{
		original: '/img/1600x900/001.jpg',
		thumbnail: '/img/100x100/001.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/002.jpg',
		thumbnail: '/img/100x100/002.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/003.jpg',
		thumbnail: '/img/100x100/003.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/004.jpg',
		thumbnail: '/img/100x100/004.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/005.jpg',
		thumbnail: '/img/100x100/005.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/006.jpg',
		thumbnail: '/img/100x100/006.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/007.jpg',
		thumbnail: '/img/100x100/007.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/008.jpg',
		thumbnail: '/img/100x100/008.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/009.jpg',
		thumbnail: '/img/100x100/009.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/010.jpg',
		thumbnail: '/img/100x100/010.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/011.jpg',
		thumbnail: '/img/100x100/011.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/012.jpg',
		thumbnail: '/img/100x100/012.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
	{
		original: '/img/1600x900/013.jpg',
		thumbnail: '/img/100x100/013.jpg',
		originalAlt: 'temp',
		thumbnailAlt: 'alt',
	},
];
