import { HashRouter } from 'react-router-dom';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import './scss/Main.scss';
import Auction2Page from './pages/Auction2';
import AuctionConditions from './pages/AuctionConditions';
import AuctionList from './classes/AuctionList';
import Conditions from './pages/Conditions';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './pages/Home';
import Imprint from './pages/Imprint';
import Navigation from './components/Navigation';
import Privacy from './pages/Privacy';
import Sell from './pages/Sell';
import Shop from './pages/Shop';
import Sidebar from './components/Sidebar';

export default function App() {
	const [auctionList, setAuctionList] = useState<AuctionList>(new AuctionList());

	useEffect((): void => {
		initialize();
	}, []);

	const initialize = async (): Promise<void> => {
		const list: AuctionList = new AuctionList();
		await list.getFromApi();
		setAuctionList(list);
	};

	function renderRouting(): JSX.Element {
		return (
			<Routes>
				<Route path="auction-conditions" element={<AuctionConditions />} />
				<Route
					path="/"
					element={
						<ProtectedRoute>
							<Navigate replace to="home" />
						</ProtectedRoute>
					}
				/>
				<Route
					path="home"
					element={
						<ProtectedRoute>
							<Home />
						</ProtectedRoute>
					}
				/>
				<Route
					path="auction/:nr"
					element={
						<ProtectedRoute>
							<Auction2Page auctionList={auctionList} />
						</ProtectedRoute>
					}
				/>
				<Route
					path="sell"
					element={
						<ProtectedRoute>
							<Sell />
						</ProtectedRoute>
					}
				/>
				<Route
					path="shop"
					element={
						<ProtectedRoute>
							<Shop />
						</ProtectedRoute>
					}
				/>
				<Route path="privacy" element={<Privacy />} />
				{/* <Route path="conditions" element={<Conditions />} /> */}
				<Route path="imprint" element={<Imprint />} />
			</Routes>
		);
	}

	return (
		<>
			<div id="page-container">
				<div id="content-wrap">
					<HashRouter>
						<Navigation auctions={auctionList.list} />

						<div className="container">
							<div className="row">
								<div className="col-lg-8">
									<div className="main">
										{renderRouting()}
									</div>
								</div>
								<div className="col-lg-4">
									<Sidebar />
								</div>
							</div>
						</div>

						<Footer />
					</HashRouter>
				</div>
			</div>
		</>
	);
}
